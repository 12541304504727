<template>
    <div class="messageContainer">
        <qy-top-nav title="消息列表" />
        <van-search shape="round" placeholder="请输入搜索关键词" v-model="queryText" @search="onSearch"/>
        <van-cell-group class="van-clearfix">
            <van-list
                    v-model="loadMore"
                    :finished="finished"
                    :offset="offset"
                    :finished-text="finishedText"
                    @load="onLoad"
            >
                    <van-cell v-for="item in list" :title="item.shopName" :label="item.msg.length > 15 ? item.msg.substring(0,14) + '...' : item.msg"  size="large" @click="showPopup(item)">
                        <template #icon>
                            <img :src="getImgUrl(item.shopImg)" style="width: 80px;height:50px;min-height:50px;margin-right: 5px;"/>
                        </template>
                        <a>{{item.createTime.substring(item.createTime.indexOf(' ')+1)}}</a><br>
                        <van-tag text-color="#ad0000" color="#ffe1e1" v-show="item.status == 0">未读</van-tag>
                    </van-cell>
            </van-list>
            <van-popup v-model="show" position="top" :style="{ height: '30%' }">
                <van-cell center :title="title" value="" :label="message" />
            </van-popup>
        </van-cell-group>
        <div style="height: 30px;width: 100%"></div>
        
    </div>
</template>
<script type="text/javascript">
import bottomTabNav from "@/components/bottomTabNav";
import request from "@/utils/request";
export default {
    name: "message",
    components: { bottomTabNav },
    data() {
        return {
            show:false,
            showStatus:false,
            queryText:'',
            message:'',
            title:'',
            shopName:'',
            list: [],
            offset:50,
            loadMore: false,
            finished: false,
            finishedText:'没有更多了',
            pageNum:1,
            pageSize:10,
        };
    },
    methods:{
        onSearch(val){
             this.list = [];
             const db = {
                queryText:val,
                pageSize:this.pageSize,
                pageNum:1,
            };
            this.getList('/api/message/queryUserMessage',db)
        },
        onLoad(){
            //是否已经登录
            let isLogin = this.checkLogin();
            if(!isLogin){
                return
            }
            //发送请求数据
            const db = {
                queryText:this.queryText,
                pageSize:this.pageSize,
                pageNum:this.pageNum,
            };
            if(!this.finished){
                this.getList('/api/message/queryUserMessage',db)
            }
        },

        getList(url,db) {
            let that = this;
            request.post(url, db).then(
                res => {
                    if (res.code === 0) {
                        if (res.data.length > 0) {
                            that.list = that.list.concat(res.data); // 追加数据
                        }
                        //最后一页
                        if (!res.hasNextPage) {
                            that.finished = true;
                            that.loadMore = false;
                        }else {
                            that.finished = false;
                            that.loadMore = false;
                            that.pageNum += 1;
                        }
                    } else {
                        that.finished = true;
                    }
                },
            );
        },
        showPopup(item){
            this.show=true;
            this.message = item.msg;
            this.title = item.createTime;
            this.shopName = item.shopName;
            request.post("/api/message/changeUserMessageStatus",{
                id:item.id
            }).then(res => {
                if(res.code != 0){
                    // console.log(res.msg);
                }else {
                    item.status = 1; //已读
                }
            });
        },
    },
}
</script>
<style type="text/css" scoped="">
.messageContainer {
    background: #f5f5f5;
    min-height: 1000px;
    padding-top: 10px;
    padding: 10px 8px;
}
.messageContainer .van-cell__title{
	flex: 3 ;
}
html, body{
    height: 100%;
    width: 100%;
}
.van-search__content--round{border:0px;}

</style>